import {createApp} from 'vue';
import { createRouter, createWebHashHistory } from 'vue-router'
import App from './App.vue';
import MainPage from './components/MainPage';
import PrivacyWindows from './components/PrivacyWindows';
import PrivacyGoogle from './components/PrivacyGoogle';
import PrimeVue from 'primevue/config';
import VueGtag from "vue-gtag";
import ScriptX from 'vue-scriptx';
import Ads from 'vue-google-adsense';

const app = createApp(App);

app.use(PrimeVue);

app.use(VueGtag, {
	config: { id: "G-JLW37MV5Q7" }
});

app.use(ScriptX);
app.use(Ads.Adsense);
app.use(Ads.InArticleAdsense);
app.use(Ads.InFeedAdsense);

const routes = [
	{ path: "/", name: "Home", component: MainPage },
	{ path: "/privacy-windows", component: PrivacyWindows },
	{ path: "/privacy-google", component: PrivacyGoogle },
	{ path: "/:catchAll(.*)", component: MainPage }
]
const router = createRouter({
	history: createWebHashHistory(),
	routes,
});
app.use(router);

app.mount("#app");

