<template>
<Button icon="pi pi-home" class="p-button my-2 mx-2 col-1 align-self-center" label="Home" title="Go to Home Page" @click="handleHome()" />
<div class="privacy-notices p-toolbar">
	<h1>Windows Store Apps</h1>
	<p>This page provides the <b>Privacy Policy</b> information for our apps on <a href="https://microsoft.com/store">Windows Store</a>.</p>
	<a id="dictator">
	<h2>Dictator</h2>
	</a>
	<p>This app requires access to your <b>Microphone</b>, because that permission is necessary to use the built-in <b>Speech Recognition</b>.  It is used for no other purpose.</p>
	<p>Dictations are stored in clear-text format on your hard drive, in the app's folder.  No information is sent off your device, unless you opt-in to one of the commands designed to send data, e.g. email or clipboard.</p>
	<p>Use of the <b>Text-to-speech</b> features may cause your dictations to be disclosed to anyone within earshot of your device.</p>
	<p>Your dictations are displayed in clear text on the main page; this makes them vulnerable to eavesdroppers.</p>
	<a id="yacc">
	<h2>YACC Demo</h2>
	</a>
	<p>This app requests no permissions, and collects no personal information.</p>
	<a id="medialab">
	<h2>MediaLab</h2>
	</a>
	<p>This app requests access to <b>Videos</b> and <b>Pictures</b> folders, and collects no personal information.</p>
</div>
</template>
<script>
import Button from 'primevue/button/sfc';

export default {
	name: "PrivacyWindows",
	components: { Button },
	methods: {
		handleHome() {
			this.$router.push({ path: "/" });
		}
	}
}
</script>
<style scoped>
</style>