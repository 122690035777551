<template>
<Button icon="pi pi-home" class="p-button my-2 mx-2 col-1 align-self-center" label="Home" title="Go to Home Page" @click="handleHome()" />
<div class="privacy-notices p-toolbar">
	<p>Some apps are no longer available.</p>
	<h1>Google Play Apps</h1>
	<p>It's time for some <i>frank and open discussion</i> about privacy.  This page provides the <b>Privacy Policy</b> information for our apps on <a href="https://play.google.com/">Google Play Store</a>.</p>
	<a id="mood-ring-thermometer"></a>
	<h2 class="p-0 m-0">Mood Ring Thermometer and Widget and Live Wallpaper</h2>
	<a href="https://play.google.com/store/apps/details?id=com.escape.free.MoodRing">App Store Listing Link</a>
	<a href="https://play.google.com/store/apps/details?id=com.escape.widget.MoodRing">Widget Store Listing Link</a>
	<p>Stores the following items locally on device:</p>
	<ul>
	<li>Settings.</li>
	</ul>
	<p>This app's primary purpose is to display graphics based on sensor data, so it can access <b>All Sensors</b> on the device, except <b>Location</b>.  It only accesses the sensor selected in the <b>Settings</b>.</p>
	<p>Since this is for entertainment only, sensor data is not persistently saved or transmitted off device.</p>
	<a id="dictator"></a>
	<h2>Dictator</h2>
	<a href="https://play.google.com/store/apps/details?id=com.escape.dictator">Store Listing Link</a>
	<p>This app stores the following items locally on device:</p>
	<ul>
	<li>Settings.</li>
	<li>The database of dictations.</li>
	</ul>
	<p>This app stores the following items locally while app is running:</p>
	<ul>
	<li>List of alternate <b>Speech Recognition Results</b>.</li>
	</ul>
	<p>This app accesses your <b>Accelerometer</b> sensor to support the <b>Shake to Trigger</b> feature.  This is only used if the feature is enabled.  No sensor data is transmitted off device.</p>
	<p>This app accesses your <b>Proximity</b> sensor to support the <b>Proximity to Trigger</b> feature.  This is only used if the feature is enabled.  No sensor data is transmitted off device.</p>
	<p>This app transmits dictation text off device by opting in to the <b>Share</b> command.  This passes data to the target app (you select) for processing.</p>
	<a id="call-log-assistant"></a>
	<h2>Call Log Assistant</h2>
	<a href="https://play.google.com/store/apps/details?id=escape.CallLogAssistant">Store Listing Link</a>
	<p>This app stores the following items locally on device:</p>
	<ul>
	<li>Settings.</li>
	<li>The XML configurations for the <b>Assistant</b> modules.</li>
	</ul>
	<p>This app accesses the <b>Call Log</b>, <b>Contacts</b> and <b>Messaging</b> databases on device.</p>
	<p>This app accesses the <b>Phone State</b> to provide the corresponding trigger modes in the <b>Assistant</b> configuration.</p>
	<p>Data may be transmitted off device by opting in to the <b>Post Data Assistant Action</b> or the <b>Calendar Item Assistant Action</b>.</p>
	<p>Incoming phone numbers or contact names may be transmitted via search URL by opting in to the <b>Screen Pop Assistant Action</b>.</p>
	<a id="toodone"></a>
	<h2>TooDone</h2>
	<p>Describe the permissions.</p>
	<a id="text-talk"></a>
	<h2>Text Talk</h2>
	<a href="https://play.google.com/store/apps/details?id=com.escape.sms.dictator">Store Listing Link</a>
	<p>This app uses the pre-existing SMS database of your device.  This is the same database any SMS app uses.  Only Settings are stored locally.</p>
	<p>This app uses the built-in <b>Speech Recognition</b> and <b>Text-to-Speech</b> features of your device.</p>
	<p>This app reads the <b>Contacts</b> database for contact group, name and phone number data.</p>
	<p>Only SMS messages you send are transmitted off device.  No other data is saved or transmitted.</p>
	<a id="roundabout"></a>
	<h2>Roundabout</h2>
	<a href="https://play.google.com/store/apps/details?id=escape.Roundabout">Store Listing Link</a>
	<p>This app stores the following items locally on device:</p>
	<ul>
	<li>Settings.</li>
	<li>The XML configurations for the roundabout modules.</li>
	<li>The database of landmarks.</li>
	</ul>
	<p>This app makes use of your GPS or other location-based-service to provide positional map information and (reverse-)geocoding.</p>
	<p>This app makes use of your current location for search-based features and proximity triggering.</p>
	<p>This app reads your <b>Contacts</b> database via the <b>Import</b> command to geocode them into the landmark database.</p>
	<p>Current location is never saved persistently or transmitted off device.  Search terms entered are transmitted off device in search URLs.</p>
	<a id="feed-farmer"></a>
	<h2>Feed Farmer</h2>
	<a href="https://play.google.com/store/apps/details?id=com.escapellc.FeedFarmer">Store Listing Link</a>
	<p>This app stores the following items locally on device:</p>
	<ul>
	<li>Settings.</li>
	<li>The list of feeds, attachments, and searches.</li>
	<li>The XML downloaded content from feed URLs.</li>
	<li>Rendered HTML content from feed items and search results.</li>
	</ul>
	<p>Search terms entered are transmitted off device in search URLs.  No other data is transmitted off the device.</p>
	<a id="darts-scorecard"></a>
	<h2>Darts Scorecard</h2>
	<a href="https://play.google.com/store/apps/details?id=com.escapellc.CricketScorecard">Store Listing Link</a>
	<p>This app stores the following items locally on device:</p>
	<ul>
	<li>Default game settings.</li>
	<li>Player names for auto-complete.</li>
	</ul>
	<p>Game data is not stored after the app is closed.  Game data can be transmitted off the device by opting-in to the <b>Share</b> command.  This passes data to the target app (you select) for processing.</p>
	<a id="exchange-my-contacts"></a>
	<h2>eXchange My Contacts</h2>
	<p>This app is a <b>sync provider</b> and requires full access to your <b>Contacts</b> and <b>Calendar</b> databases.</p>
	<p>This app stores the following items locally on device:</p>
	<ul>
	<li>Information from your <b>Exchange Web Service</b>.</li>
	<li>During autoconfigure, your email address is sent to pre-defined URLs.</li>
	<li>Your <b>credentials</b> stored in protected format by the Android system.</li>
	<li>Any message bodies you opt-in to download for viewing.</li>
	</ul>
	<p>Search terms and Exchange mailbox addresses are transmitted off device in EWS messages.</p>
</div>
</template>
<script>
import Button from 'primevue/button/sfc';

export default {
	name: "PrivacyGoogle",
	components: { Button },
	methods: {
		handleHome() {
			this.$router.push({ path: "/" });
		}
	}
}
</script>
<style scoped>
</style>