<template>
	<Toolbar class="p-1">
		<template #start>
			<img alt="eScape logo" height="64" src="./assets/escape-logo-blue-glass-280x280.png">
			<div class="banner-text ml-3"><span class="logo-red">e</span><span class="logo-blue">Scape</span><span> Technology LLC</span></div>
		</template>
		<template #end>
			<span class="p-buttonset">
				<SplitButton icon="pi pi-lock" class="p-button-warning" label="Privacy" title="View privacy policies" :model="privacy" />
				<Button icon="pi pi-home" class="p-button" label="Apps" title="Go to Wordpress site" @click="handleApps()" />
				<Button icon="pi pi-book" class="p-button" label="Docs" title="Go to Documentation site" @click="handleDoc()" />
				<Button icon="pi pi-send" class="p-button" label="Contact" title="Contact us!" @click="handleContact()" />
			</span>
		</template>
	</Toolbar>
	<router-view />
	<footer class="footer p-3">
		<div class="flex flex-row justify-content-center align-items-center">
			<h1 class="mr-2">Powered By</h1>
			<template v-for="pb in poweredBy" :key="pb.href">
				<div class="mx-2 text-xl"><img class="icon" :src="pb.src"/><a class="link" :href="pb.href">{{pb.link}}</a></div>
			</template>
		</div>
		<DeferredContent>
			<div class="mt-3 flex flex-row justify-content-center align-items-center">
				<div class="mx-3">
					<a href="//stackoverflow.com/users/650049/escape-llc">
					<img src="//stackoverflow.com/users/flair/650049.png" width="208" height="58" alt="profile for escape-llc at Stack Overflow, Q&amp;A for professional and enthusiast programmers" title="profile for escape-llc at Stack Overflow, Q&amp;A for professional and enthusiast programmers" />
					</a>
				</div>
			</div>
		</DeferredContent>
		<div class="mt-3 pt-2 px-2 text-xl p-panel">&copy;2005-2023 <span class="logo-red">e</span><span class="logo-blue">Scape</span><span> Technology LLC</span></div>
	</footer>
	<Dialog v-model:visible="showContactDialog" :modal="true">
		<template #header>
			<div class="flex flex-column">
				<h3>Compose Email</h3>
				<div>After you select <b>Send</b>, this will start your email client.</div>
			</div>
		</template>
		<div class="my-3">
			<span class="p-label">
				<label for="subject">Subject</label>
				<InputText id="subject" type="text" v-model="emailSubject" style="width:100%" class="my-3" />
			</span>
			<span class="p-label">
				<label for="message">Message</label>
				<Textarea id="message" v-model="emailMessage" style="width:100%" class="mt-3" />
			</span>
		</div>
		<template #footer>
			<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="handleCancelEmail()" />
			<Button label="Send" icon="pi pi-send" @click="handleSendEmail()" />
		</template>
	</Dialog>
</template>

<script>
import MainPage from './components/MainPage.vue'
import DeferredContent from 'primevue/deferredcontent/sfc';
import Toolbar from 'primevue/toolbar/sfc';
import Button from 'primevue/button/sfc';
import SplitButton from 'primevue/splitbutton/sfc';
import Dialog from 'primevue/dialog/sfc';
import InputText from 'primevue/inputtext/sfc';
import Textarea from 'primevue/textarea/sfc';

import "primeflex/primeflex.css";
import "primevue/resources/themes/bootstrap4-dark-purple/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

const default_poweredby = [
	{
		src:"https://vuejs.org/logo.svg",
		href: "https://vuejs.org",
		link: "Vue JS"
	},
	{
		src:"https://www.primefaces.org/wp-content/uploads/fbrfg/favicon.ico",
		href: "https://primefaces.org",
		link: "PrimeVUE"
	},
	{
		src:"https://google.com/favicon.ico",
		href: "https://google.com",
		link: "Google"
	},
	{
		src:"https://cdn.vsassets.io/content/icons/favicon.ico",
		href: "https://azure.microsoft.com/en-us/services/devops/",
		link: "Azure DevOps"
	},
	{
		src: "https://code.visualstudio.com/favicon.ico",
		href: "https://code.visualstudio.com/",
		link: "VS Code"
	},
	{
		src: "https://dotnet.github.io/docfx/favicon.ico",
		href: "https://dotnet.github.io/docfx/",
		link: "DocFX"
	},
	{
		src: "https://wordpress.com/favicon.ico",
		href: "https://wordpress.com/",
		link: "WordPress"
	},
	{
		src:"https://justhost.com/favicon.ico",
		href: "https://justhost.com",
		link: "JustHost.com"
	},
	{
		src:"https://name.com/favicon.ico",
		href: "https://name.com",
		link: "name.com"
	},
];
const default_privacy = [
	{
		label: "Windows",
		icon: "pi pi-microsoft",
		to: "/privacy-windows",
	},
	{
		label: "Android",
		icon: "pi pi-android",
		to: "/privacy-google",
	}
];

export default {
	name: 'App',
	components: {
		DeferredContent, Toolbar, Button, SplitButton,
		Dialog, InputText, Textarea,
		MainPage
	},
	methods: {
		handlePrivacy() {
			this.$gtag.event({
				'event_category': "click",
				'event_label': "Privacy",
				'value': 4
			});
			window.location.assign("https://escape-technology-llc.com/privacy/windows-store.html");
		},
		handleSendEmail() {
			this.showContactDialog = false;
			try {
				const email = 'common@escape-technology-llc.com';
				const mailto_link = 'mailto:' + email + '?subject=' + encodeURIComponent(this.emailSubject) + '&body=' + encodeURIComponent(this.emailMessage);
				const win = window.open(mailto_link, 'emailWindow');
				// this auto-closes the window for debug purposes
				//if (win && win.open && !win.closed) win.close();
			}
			catch(e) {
				console.error("handleSendEmail", e);
			}
		},
		handleCancelEmail() {
			this.showContactDialog = false;
		},
		handleContact() {
			this.$gtag.event({
				'event_category': "click",
				'event_label': "Contact",
				'value': 1
			});
			this.showContactDialog = true;
		},
		handleApps() {
			this.$gtag.event({
				'event_category': "click",
				'event_label': "Apps",
				'value': 2
			});
			window.location.assign("https://escape-technology-llc.com/mobile");
		},
		handleDoc() {
			this.$gtag.event({
				'event_category': "click",
				'event_label': "Documentation",
				'value': 3
			});
			window.location.assign("https://escape-technology-llc.com/documentation/escapellc-uwp-core");
		},
	},
	data() {
		return {
			showContactDialog: false,
			emailSubject: null,
			emailMessage: null,
			poweredBy: default_poweredby,
			privacy: default_privacy,
		}
	}
}
</script>
<style>
html, body {
	background: var(--surface-0);
	color: var(--text-color);
	font-family: var(--font-family);
	margin: .2rem;
	padding: 0;
}
#app {
	width: 100%;
	-webkit-font-smoothing: antialiased;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
}
.logo-blue {
	color: #6666ff;
	font-style: italic;
}
.logo-red {
	color: red;
	font-style: italic;
}
.banner-text {
	font-size: 3rem;
	font-weight: bold;
}
.icon {
	height:32px;
	display: inline-block;
	margin-right: .5rem;
	vertical-align: bottom;
}
a.link {
	display: inline-block;
	color: white;
	padding: 2px;
}
a.link:hover {
	display: inline-block;
	color:black;
	padding: 2px;
}
a.link:visited {
	display: inline-block;
	color:gray;
	padding: 2px;
}
.privacy-notices {
	display: block;
	text-align: start;
}
</style>
