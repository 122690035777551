<template>
	<div class="main">
		<DeferredContent @load="loadMotd()">
			<div class="text-3xl my-3">{{ motd }}</div>
		</DeferredContent>
		<blockquote>You <b>may</b> need to Clear Cache/Hard Reload to see all the site updates.</blockquote>
		<h2 class="p-0 mb-2">News</h2>
		<DeferredContent @load="loadNews()">
			<DataView :value="feed.items" layout="grid" :paginator="true" :rows="6">
				<template #grid="slotProps">
					<div class="p-toolbar col-12 md:col-4">
						<div class="card mx-2 my-2">
							<div class="flex flex-row align-items-center">
								<img style="display:inline-block" v-if="slotProps.data.image" height="32" class="mr-2" :src="slotProps.data.image"/>
								<div class="flex-grow-1" style="text-align:start">
									<div class="card-title">{{ slotProps.data.title }}</div>
									<div class="card-subtitle">{{ slotProps.data.timestamp }}</div>
								</div>
							</div>
							<div class="card-body">{{ slotProps.data.body }}</div>
							<div v-if="slotProps.data.link" class="card-footer"><a class="link" :href="slotProps.data.link.href">{{ slotProps.data.link.text }}</a></div>
						</div>
					</div>
				</template>
			</DataView>
		</DeferredContent>
		<div class="flex flex-row align-items-center justify-content-center text-xl my-3"><div>More information is on our Wordpress site</div><Button icon="pi pi-home" class="p-button ml-2" label="Apps" title="Go to Wordpress site" @click="handleApps()" /></div>
		<DeferredContent @load="loadProjects()">
			<h2 class="p-0 mb-2">Projects</h2>
			<div class="p-toolbar flex flex-row flex-wrap justify-content-center align-items-center">
				<template v-for="project in projects" :key="project.href">
					<div class="mx-2 text-2xl col-3"><img class="icon" :src="project.src"/><a class="link" :href="project.href">{{ project.link }}</a> {{ project.text }}</div>
				</template>
			</div>
		</DeferredContent>
		<div class="flex flex-row align-items-center justify-content-center text-xl my-3"><div>More information is on our Documentation site</div><Button icon="pi pi-book" class="p-button ml-2" label="Docs" title="Go to Documentation site" @click="handleDoc()" /></div>
		<h2 class="p-0 mb-2">Always the $$$...</h2>
		<DeferredContent>
			<div class="p-toolbar flex flex-row justify-content-center align-items-center">
				<div class="mx-3 text-xl">
					<div class="mb-2">
						<b>Using our Free Apps?</b>
					</div>
					<form action="https://www.paypal.com/cgi-bin/webscr" method="post"><input type="hidden" name="cmd" value="_s-xclick" /> <input type="hidden" name="hosted_button_id" value="LFW7NRZ374UTL" /> <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" /> <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" /></form>
					<div class="mt-2">Your Support is Welcomed</div>
				</div>
				<Adsense class="mr-3" style="width:180px;height:460px"
					is-new-ads-code="yes"
					data-ad-client="ca-pub-1570519290964147"
					data-ad-format="auto"
					data-full-width-responsive="yes"
					data-ad-slot="5160773451">
				</Adsense>
				<iframe width="180" height="460" scrolling="no" style="overflow:hidden" src="https://leanpub.com/softwaredevelopmentaxioms/embed" frameborder="0" allowtransparency="true"></iframe>
			</div>
		</DeferredContent>
		<DeferredContent @load="loadProfiles()">
			<h2 class="p-0 mb-2">Profiles</h2>
			<div class="p-toolbar flex flex-row justify-content-center align-items-center">
				<template v-for="profile in profiles" :key="profile.href">
					<div class="mx-2 text-2xl col-3"><img class="icon" :src="profile.src"/><a class="link" :href="profile.href">{{ profile.link }}</a><span>{{ ' ' + profile.text }}</span></div>
				</template>
			</div>
		</DeferredContent>
	</div>
</template>

<script>
import Toolbar from 'primevue/toolbar/sfc';
import Button from 'primevue/button/sfc';
import SplitButton from 'primevue/splitbutton/sfc';
import Panel from 'primevue/panel/sfc';
import Card from 'primevue/card/sfc';
import DataView from 'primevue/dataview/sfc';
import Chip from 'primevue/chip/sfc';
import DeferredContent from 'primevue/deferredcontent/sfc';
import Dialog from 'primevue/dialog/sfc';
import InputText from 'primevue/inputtext/sfc';
import Textarea from 'primevue/textarea/sfc';

const error_feed = {
	"title": "eScape Technology Item Feed",
	"lastUpdate": new Date().toUTCString(),
	items: [
		{
			id: 1,
			image: "https://escape-technology-llc.com/favicon.ico",
			title: "Feed Error",
			timestamp: new Date().toString(),
			body: "Error reading the feed."
		}
	]
};
const default_motd = "We are a creator of Open Source and publisher of Apps on multiple platforms.";
const default_profiles = [
	{
		src: "https://apps.microsoft.com/favicon.ico",
		href: "https://apps.microsoft.com/store/search?publisher=eScape%20Technology%20LLC",
		link: "Windows Store",
		text: "publisher"
	},
	{
		src: "https://google.com/favicon.ico",
		href: "https://play.google.com/store/apps/developer?id=eScape+Technology+LLC",
		link: "Google Play",
		text: "publisher"
	},
	{
		src: "https://nuget.org/favicon.ico",
		href: "https://www.nuget.org/profiles/escape-technology-llc",
		link: "nuget.org",
		text: "profile"
	},
	{
		src: "https://github.com/favicon.ico",
		href: "https://github.com/escape-llc",
		link: "github.com",
		text: "profile"
	},
	{
		src:"https://stackoverflow.com/favicon.ico",
		href:"//stackoverflow.com/users/650049/escape-llc",
		link: "StackOverflow",
		text: "profile"
	}
];
const default_projects = [
	{
		src:"https://nuget.org/favicon.ico",
		href: "https://www.nuget.org/packages/eScapeLLC.UWP.Charts/",
		link: "eScapeLLC.UWP.Charts",
		text: "UWP development package"
	},
	{
		src:"https://nuget.org/favicon.ico",
		href: "https://www.nuget.org/packages/eScapeLLC.UWP.Core/",
		link: "eScapeLLC.UWP.Core",
		text: "UWP development package"
	},
	{
		src:"https://nuget.org/favicon.ico",
		href: "https://www.nuget.org/packages/eScapeLLC.UWP.Geodesy/",
		link: "eScapeLLC.UWP.Geodesy",
		text: "UWP development package"
	},
	{
		src:"https://nuget.org/favicon.ico",
		href: "https://www.nuget.org/packages/eScapeLLC.Standard.EventBus/",
		link: "eScapeLLC.Standard.EventBus",
		text: ".NET Standard development package"
	},
	{
		src:"https://d2sofvawe08yqg.cloudfront.net/softwaredevelopmentaxioms/s_hero?1620517010",
		href: "https://leanpub.com/softwaredevelopmentaxioms",
		link: "Software Development Axioms",
		text: "eBook"
	},
	{
		src:"https://github.com/favicon.ico",
		href: "https://github.com/escape-llc/yet-another-chart-component",
		link: "YACC",
		text: "UWP XAML Chart component"
	},
	{
		src: "https://apps.microsoft.com/favicon.ico",
		href: "https://apps.microsoft.com/store/detail/medialab/9PP8Q6XVH1BQ",
		link: "MediaLab",
		text: "Windows Store App"
	},
	{
		src: "https://apps.microsoft.com/favicon.ico",
		href: "https://apps.microsoft.com/store/detail/dictator-for-uwp/9PJX4CP1LJN2",
		link: "Dictator for UWP",
		text: "Windows Store App"
	},
];

export default {
  name: "MainPage",
	components: { Toolbar, Button, SplitButton, Panel, DataView, Card, Chip, DeferredContent, Dialog, InputText, Textarea },
  props: {
  },
	methods: {
		handleApps() {
			this.$gtag.event({
				'event_category': "click",
				'event_label': "Apps",
				'value': 2
			});
			window.location.assign("https://escape-technology-llc.com/mobile");
		},
		handleDoc() {
			this.$gtag.event({
				'event_category': "click",
				'event_label': "Documentation",
				'value': 3
			});
			window.location.assign("https://escape-technology-llc.com/documentation/escapellc-uwp-core");
		},
		loadNews() {
			fetch("/feed.json")
			.then(resp => resp.json())
			.then(json => { this.feed = json; })
			.catch(ex => {
				console.error("feed failed", ex);
				this.feed = error_feed;
			});
		},
		loadMotd() {
			fetch("/motd.json")
			.then(resp => resp.json())
			.then(json => {
				const dt = Date.parse(json.expires);
				const now = new Date();
				if(dt > now.getTime()) {
					this.motd = json.message;
				}
				else {
					this.motd = default_motd;
				}
			})
			.catch(ex => {
				console.error("motd failed", ex);
				this.motd = default_motd;
			});
		},
		loadProjects() {
			fetch("/projects.json")
			.then(resp => resp.json())
			.then(json => {
				this.projects = json;
			})
			.catch(ex => {
				console.error("projects failed", ex);
				this.projects = default_projects;
			});
		},
		loadProfiles() {
			fetch("/profiles.json")
			.then(resp => resp.json())
			.then(json => {
				this.profiles = json;
			})
			.catch(ex => {
				console.error("profiles failed", ex);
				this.profiles = default_profiles;
			});
		},
	},
	data() {
		return {
			feed: { items:[] },
			motd: "We are a creator of Open Source and publisher of Apps on multiple platforms.",
			profiles: default_profiles,
			projects: default_projects,
		}
	},
};
</script>
<style scoped>
.card {
	padding: 1rem;
	border-radius: 4px;
	height: 100%;
	width: 100%;
}
.card-title {
	font-weight: bold;
}
.card-subtitle {
	font-style: italic;
}
.card-body {
	width: 100%;
	margin-top: .25rem;
	text-align: start;
}
.card-footer {
	width: 100%;
	margin-top: .5rem;
}
</style>
